<template>
  <div>
    <div class="title flexB" >
      <h1>{{ $t("top-select-total-view-live") }}</h1>
      <div>

      </div>
    </div>
    <div class="contents liveStatus2">
      <div class="boxCol flexB">
        <div class="box liveWrap" >
          <h2 class="bold" style="display: inline-block">
            {{ $t("top-select-live-video-monitor") }}
          </h2>
          <p style="display: inline-block; margin-left: 30px;">
            <span>
              {{ $t("data-total") }} {{channelListCounts.assignedCount}} {{ $t("data-default-ea") }} {{ $t("data-ch-assigned") }}
            </span>
<!--            중계 중인 값 표시 (주석) -->
            <span>
              /
            </span>
            <span>
              {{ $t("data-ch-on-air") }} {{channelListCounts.recordCount}}{{$t("data-default-ea")}}
            </span>
          </p>
          <div class="flexL">
            <template  v-for="(index) in 9">
              <div class="live" :key="index">

                <div style="width: 100%;" v-if="getLiveData(index) != undefined && index != 5" v-show="getLiveData(index).isLoad == true">
                  <div v-show="getLiveData(index).isLoad == true" class="flex" :class="{ 'live-active' : ( currentLive != null && currentLive.channelId == getLiveData(index).channelId )}" style="z-index: 2; width: 100%; height: 40px;opacity: 0.7; background-color: #000; color: white; position: absolute"  >

                    <div v-if="getLiveData(index).isLoad == true " style="width: 100%; text-align: center;  padding-top:13px; padding-left: 5px; padding-left: 5px;" @click="clickLive(getLiveData(index))">
                      <span class="channel" style="left: 0px;" >{{getLiveData(index).eventTitle}}</span> /
                      <span class="channel" style="left: 0px;" >{{getLiveData(index).assignedDeviceType != 'Mobile' ? getLiveData(index).assignedDeviceName : getLiveData(index).assignedDepartmentName}}</span> -
                      <span class="channel" style="right:0px;" >{{getLiveData(index).assignedDeviceType != 'Mobile' ? getLiveData(index).assignedUserName : getLiveData(index).assignedUserName}}</span>
                    </div>

                    <button  style="right: 3px; position: absolute; margin-top: 3px;" @click="playerOpen('live', getLiveData(index).channelId)">
                      <img src="@/assets/images/icon_svg/ic_pop_up.svg" alt="icon"/>
                    </button>

                  </div>
                  <Video class="live-video" :src="getLiveUrl(getLiveData(index))" :isVod="false" :isAuto = "true" :isControls="false" :playerId="getLiveData(index).channelId" @isLoadedmetadata="playerLoadData" />
                </div>

                <template v-if="index != 5 && (getLiveData(index) == undefined || getLiveData(index).isLoad == false)">
                  <div style="width: 100%; z-index: 4;" v-if="getLiveData(index) == undefined || getLiveData(index).isLoad == false" class="novideo">
                    <span>{{$t("ready-live")}}</span>
                  </div>
                </template>

                <div v-if="index == 5" style=" min-width: 100%; min-height: 100%; height: 100%;">
                  <Map ref="map" style="position: absolute; z-index: 2;" :loadMapName="loadMap"/>
                  <img src="@/assets/images/map/map_empty_img.png" style = "z-index: 1; position: relative; width: 100%; height: auto; object-fit: fill;" />
                </div>

              </div>

            </template>
          </div>
        </div>
      </div>
      <div class="pagination box">
        <!-- page는 진행중인 이벤트 갯수만큼 나와야함. 리스트의 데이터 갯수는 8개, 페이지 사이즈는 1개-->
        <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="currentPage - 1"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<style scoped>
.live-active {
  background-color: #e34307 !important;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import Video from "@/components/video/videoPlayer";

import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapDataType from "@/components/map/enum/MapDataType";

import { baseUrl } from "@/config/env";
import { mapState } from "vuex";
import { fetchChannelList, fetchChannelListCounts,  getLiveUrl } from "@/api/channel";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {playerPopOpen} from "@/util/popup";

export default {
  name: "LiveStatus",
  components: {
    Video, Map,
  },
  data() {
    return {
      index: 0,
      accountId: localStorage.getItem("accountId"),
      baseUrl: baseUrl,
      moment: moment,
      currentPage: 0,
      total: 0,
      size: 8,
      channelListCounts: {waitCount: 0, assignedCount: 0, recordCount: 0, errorCount: 0},
      liveList: [],
      viewChannelList : [],
      viewSrc: "",
      channelId: "",
      currentLive: null,
      loadMap : 'kakao',
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 1, sub: 2 });
  },
  async mounted() {
    await this.getLiveList();

    this.positionInterval = setInterval( () => {
      this.updateMapPosition()
    }, 1000);

    this.interval = setInterval(() => {
      this.getLiveList();
    }, 3000);

    this.initMapData(this.currentLang );
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.$nextTick(function(){
        this.setMapSetting();
        if(this.currentLive != null){
          this.getEventPoint();
          this.channelToMapMarker(this.currentLive);
          this.updateMapPosition();
        }
      })
    },
    setMapSetting(){
      this.$refs.map.onShowButtonTypeChange();
    },

    async getEventPoint() {
      let params = {
        eventId : this.currentLive.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
          this.$refs.map.setLayout();
        }
      });
    },
    /**
     * 화면 보여주는 데이터의 인덱스 보정 후 값 가져오기
     * @param index
     */
    playerLoadData(id, isLoad){
      var isFindIndex = (data) => {
        return data.channelId == id;
      };
      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if(findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getLiveData(index){
      if(index == 5){
        return;
      }
      var currentIndex = index < 5 ? index - 1 : index -2;
      return this.viewChannelList[currentIndex];
    },
    getLiveUrl(channelData){
      var url = getLiveUrl(channelData);
      if(url == '-') {
        return ''
      }
      return url
    },
    channelToMapMarker(channelData){
      let mapDataType = MapDataType.ID_DRONE
      let pointMapMarker = new MapMarkerData();
      pointMapMarker.id = channelData.channelId;
      pointMapMarker.lng = channelData.lng;
      pointMapMarker.lat = channelData.lat;
      pointMapMarker.rotate = channelData.azimuth;
      if(this.$refs.map[0].onIsMarker(mapDataType,channelData.channelId ) ) {
        this.$refs.map[0].onModifyMarkerAt(mapDataType, pointMapMarker);
      } else {
        this.$refs.map[0].onCreateMarker(mapDataType, pointMapMarker);
      }
    },

    clickLive(live){
      this.$refs.map[0].onClearMapData();
      this.$refs.map[0].setLayout();
      if(this.currentLive == live){
        this.currentLive = null;
        return;
      }
      this.$refs.map[0].onSetZoomLevel(6);
      this.currentLive = live;
      this.$refs.map[0].onLngLatToMoveCenter(this.currentLive.lng, this.currentLive.lat);
      this.channelToMapMarker(this.currentLive);
      this.getEventPoint();
    },

    async updateMapPosition() {
      if(this.currentLive == null) {
        return;
      }

      let params = {
        pageNumber: 0,
        channelId : this.currentLive.channelId,
      };
      await fetchChannelList(params).then((res) => {
        let content = res.data.data.content;
        if(content.length > 0) {
          var channelData = content[0];
          this.channelToMapMarker(channelData);
        }
      });
    },

    async getLiveList() {
      let params = {
        pageNumber: 0,
        pageSize: this.size,
        assignedStatus: "Assigned",
      };
      await fetchChannelList(params).then((res) => {
        this.total = res.data.data.total;
        this.liveList = res.data.data.content;
        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if(findIndex != -1) {
            delIds.splice(findIndex,1);
          }
        });

        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
          if(findIndex != -1){
            this.viewChannelList.splice(findIndex,1);
          }
        })

        //채널 추가
        for(var i = 0; i < this.liveList.length ; i++) {
          let live = this.liveList[i];
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == live.channelId);
          if(findIndex == -1){
            live.isLoad = false;
            this.viewChannelList.push(live)
          }
        }

      });

      await fetchChannelListCounts(params).then((res) => {
        if (res.data.result == 0) {
          this.channelListCounts = res.data.data

        } else {
          alert(res.data.message)
        }
      });
    },

    async handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.viewChannelList = [];
      await this.getLiveList();
    },

    async playerOpen(type,id){
      playerPopOpen(type,id);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.positionInterval);
  },
};
</script>
